class KonversiProductHelper {
    getUom1234StringUom(fmaterial, pcsOrUom4){
        let strUom1 = ""
        let strUom2 = ""
        let strUom3 = ""
        let strUom4 = ""

        if (getUom1FromSmallest(fmaterial, pcsOrUom4) ==0){
            strUom1 = ""
        }else {
            strUom1 += getUom1FromSmallest(fmaterial, pcsOrUom4) + " " + fmaterial.uom1
        }
        if (getUom2FromSmallest(fmaterial, pcsOrUom4) ==0){
            strUom2 = ""
        }else {
            strUom2 += getUom2FromSmallest(fmaterial, pcsOrUom4) + " " + fmaterial.uom2
        }
        if (getUom3FromSmallest(fmaterial, pcsOrUom4) ==0){
            strUom3 = ""
        }else {
            strUom3 += getUom3FromSmallest(fmaterial, pcsOrUom4) + " " + fmaterial.uom3
        }
        if (getUom4FromSmallest(fmaterial, pcsOrUom4) ==0){
            strUom4 = ""
        }else {
            strUom4 += getUom4FromSmallest(fmaterial, pcsOrUom4) + " " + fmaterial.uom4
        }

        return strUom1 + " " + strUom2 + " " + strUom3+ " " + strUom4
    }
}

function getUom1FromSmallest(fmaterial, pcsOrUom4){
    let value =0
    if (fmaterial.convfact1 !=0 && pcsOrUom4 !=0){
        value = pcsOrUom4 /fmaterial.convfact1
    }
    return parseInt(value)
}
function getUom2FromSmallest(fmaterial, pcsOrUom4){
    let value =0
    if (fmaterial.convfact1 ==1){
        value = 0
    }else {
        let sisaQtyUom1 = pcsOrUom4 % fmaterial.convfact1
        value = sisaQtyUom1 / fmaterial.convfact2
    }
    return parseInt(value)
}
function getUom3FromSmallest(fmaterial, pcsOrUom4) {
    let value = 0
    if (fmaterial.convfact1 ==1){
        value = 0
    }else if (fmaterial.convfact2 ==1) {
        value = 0
    }else {
        let sisaQtyUom1 = pcsOrUom4 % fmaterial.convfact1
        let sisaQtyUom2 = sisaQtyUom1 % fmaterial.convfact2
        value = sisaQtyUom2 / fmaterial.convfact3
    }
    return parseInt(value)
}
function getUom4FromSmallest(fmaterial, pcsOrUom4) {
    let value = 0
    if (fmaterial.convfact1 ==1){
        value = 0
    }else if (fmaterial.convfact2 ==1) {
        value = 0
    }else if (fmaterial.convfact3 ==1) {
        value = 0
    }else {
        let sisaQtyUom1 = pcsOrUom4 % fmaterial.convfact1
        let sisaQtyUom2 = sisaQtyUom1 % fmaterial.convfact2
        let sisaQtyUom3 = sisaQtyUom2 % fmaterial.convfact3
        value = sisaQtyUom3
    }
    return parseInt(value)
}

export default new KonversiProductHelper()