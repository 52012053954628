import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FStockService {
    getApiUrl() {
        return API_URL
    }

    getAllByFmaterialBeanInAndFwarehouseBeanInAndStockDateGroupByFmaterialBean(item){
        return axios.post(API_URL + `getAllByFmaterialBeanInAndFwarehouseBeanInAndStockDateGroupByFmaterialBean`, item, { headers: authHeader() });
    }
    getAllByFmaterialBeanInAndFwarehouseBeanInAndStockDate(item){
        return axios.post(API_URL + `getAllByFmaterialBeanInAndFwarehouseBeanInAndStockDate`, item, { headers: authHeader() });
    }
    getAllByFmaterialBeanInAndFwarehouseBeanInAndStockDateGoodStockOnly(item){
        return axios.post(API_URL + `getAllByFmaterialBeanInAndFwarehouseBeanInAndStockDateGoodStockOnly`, item, { headers: authHeader() });
    }


}
export default new FStockService()
