<template>
  <div>
    <WarningStockTable
    ></WarningStockTable>
  </div>
</template>

<script>
import WarningStockTable from "@/components/inventory/warning-stock/WarningStockTable";
export default {
  name: "WarningStockView",
  components: {WarningStockTable  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>